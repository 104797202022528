<template>

<div>
</div>

</template>

<script>
import VideoService from '../VideoService';
import store from '../store/store'
import { mapStores } from 'pinia';
export default {
    data(){
        return{
            token: null,
        }
    },
    async created() {

        if(this.$route.query.code){
            this.token = await VideoService.getToken(this.$route.query.code) 
        }
        if(this.token!=null){
      
            this.tokenStore.setToken(this.token)

            this.$router.push('/')
  
        }

    },
    computed: {
      ...mapStores(store)
    },
}
</script>
<style scoped>

</style>